import axios, { type AxiosAdapter } from "axios";
import {
  cacheAdapterEnhancer,
  throttleAdapterEnhancer,
} from "axios-extensions";

axios.create({
  adapter: throttleAdapterEnhancer(
    cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, {
      cacheFlag: "useCache",
    }),
  ),
});

axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.baseURL = "/api/v2/";
