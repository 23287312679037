const tinycolor = require("tinycolor2");

const getBrandingSettings = (merchant: any) => {
  return {
    hasPremium: merchant?.premium,
    hasCustomBranding: merchant?.customBranding,
    brandColor: merchant?.brandColor,
    accentColor: merchant?.accentColor,
    brandLogo: merchant?.brandLogo,
    tinyBrandColor: tinycolor(merchant?.brandColor),
    tinyAccentColor: tinycolor(merchant?.accentColor),
  };
};

export default getBrandingSettings;
