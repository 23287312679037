import * as Sentry from "@sentry/react";
import type { Event, EventHint } from "@sentry/types";

import { variables } from "../config/variables";

interface UserReportedError extends Error {
  _isUserReported?: boolean;
  _user?: {
    name: string;
    email: string;
  };
}

const MAMO_PROD_URLS = [
  "https://dashboard.mamopay.com/",
  "https://business.mamopay.com/",
];

// Available only on production env
export const sentrySessionReplay = new Sentry.Replay({
  networkDetailAllowUrls: MAMO_PROD_URLS,
  networkCaptureBodies: true,
  // Additional SDK configuration goes in here:
  maskAllInputs: variables.isProduction,
  maskAllText: false,
});

export function loggerInit() {
  // To setup performance monitoring on the FE
  Sentry.addTracingExtensions();

  Sentry.init({
    dsn: variables.sentry.dsn,
    environment: variables.isProduction ? "production" : "staging",
    release: process.env.SERVER_VERSION,

    // Integrations for replay are adding after init
    integrations: [new Sentry.BrowserTracing() as any],

    // For finer control of sent transactions you can adjust this value, or
    // use tracesSampler
    tracesSampleRate: 0.033,

    // Sample rate of the error events. Default is 1.0
    sampleRate: 0.4,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: MAMO_PROD_URLS,

    // This sets the sample rate to be 5%.
    replaysSessionSampleRate: variables.isProduction ? 0.05 : 0,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: variables.isProduction ? 0.4 : 0,

    // The /i means case insensitive. CSS or Css
    // The below is a regex for dynamically handling the loading chunk errors
    // CSS or the number are optional
    // list of ignoring errors https://gist.github.com/pioug/b006c983538538066ea871d299d8e8bc
    ignoreErrors: [
      /Loading (Css )?chunk( \d+)? failed/i,
      /change_ua/,
      /.*User closed the Payment Request UI.*/,
      /.*No response from iframe '(card_expiration|card_holder_name|card_security_code|card_number)' during 3000 ms*/,
      /.*The operation was aborted*/,
      /The ReactOnRails value exists in the \[object Window\] scope/,
      /.*NotAllowedError: Write permission denied.*/,
      "The ReactOnRails value exists",
      "Write permission denied",
      "Clipboard write was blocked due to lack of user activation.",
      "Quora Pixel Error: Base pixel code is not installed properly.",
      "Request failed with status code 401",
    ],

    beforeSend(event: Event, hint?: EventHint): Event | null {
      // Enable suspect commits
      if (event.release) {
        event.release = `${event.release}-suspect-commits`;
      }

      if (!variables.isProduction) {
        // Enable User Feedback on staging
        if (
          hint?.originalException &&
          hint.originalException instanceof Error
        ) {
          const error: UserReportedError = hint.originalException;
          error._isUserReported && (event.user = error._user);
        }
      }
      return event;
    },
  });

  // Session Replay Integration
  sentrySessionReplay.setupOnce();
  Sentry?.getCurrentHub()?.getClient()?.addIntegration?.(sentrySessionReplay);
  Sentry?.getCurrentHub()?.getClient()?.addIntegration?.(new Sentry.Dedupe());
}
