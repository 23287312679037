import React from "react";
import { render } from "react-dom";

// import { StyleProvider } from "./style";
import { PaymentLinkRoutes } from "../pages";
import "../config/request";

document.addEventListener("DOMContentLoaded", () => {
  render(
    <React.StrictMode>
      {/* <StyleProvider> */}
      <PaymentLinkRoutes />
      {/* </StyleProvider> */}
    </React.StrictMode>,
    document.body.appendChild(document.createElement("div")),
  );
});
